import React from 'react';
import Cards from '../components/Cards';
import Title from '../components/Title';
import Carousel from '../components/Carousel';

const Home = () => {
	return(
		<div>
			<Carousel/>
			<Title title="¿Por qué elegirnos?"/>
			<Cards/>
		</div>
	)
}
export default Home