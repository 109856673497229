import React from 'react';

const list = [
	{
		title: "Calidad",
		description: "La calidad de nuestro trabajo se caracteriza por sus diseños integrales: innovación, tecnología, accesibilidad, seguridad y comodidad."
	},
	{
		title: "Eficacia",
		description: "Nos especializamos en ser eficaces a la hora de realizar las instalaciones y/o mantenimiento de nuestros servicios."
	},
	{
		title: "Personal altamente calificado",
		description: "Contamos con personal altamente calificado para la realización de los proyectos según sus requerimientos y necesidades."
	},
	{
		title: "Seguridad",
		description: "Contratar nuestros servicios garantiza la seguridad para su familia."
	},
];

const Cards = () => {
	return(
		<div className="card__container">
			{list.map((e)=>(
				<div className="card">
					<h2 className="card__title">{e.title}</h2>
					<p className="card__description">{e.description}</p>
				</div>
			))}
		</div>
	)
}
export default Cards;