import { Grid } from "@material-ui/core";
import React from "react";
import {
  FaFacebook,
  FaInstagram,
  FaWhatsapp,
  FaPhoneAlt,
} from "react-icons/fa";
import { HiLocationMarker } from "react-icons/hi";

const Footer = () => {
  return (
    <div className="footer">
      <h1 className="footer__title">ColdFresh RV</h1>
      <Grid container justify="center" alignContent="center">
        <Grid item xs={12} md={4} style={{ marginBottom: "15px" }}>
          <a
            className="footer__text"
            href="tel:+51971621496"
            target="_blank"
            rel="noopener noreferrer"
          >
            <Grid item container align="center" justify="center">
              <FaPhoneAlt className="footer__icon" />
              <span className="footer__description">971 621 496</span>
            </Grid>
          </a>
        </Grid>
        <Grid item xs={12} md={4} style={{ marginBottom: "15px" }}>
          <a
            className="footer__text"
            href="https://wa.me/+51910660870"
            target="_blank"
            rel="noopener noreferrer"
          >
            <Grid item container align="center" justify="center">
              <FaWhatsapp className="footer__icon" />
              <span className="footer__description">910 660 870</span>
            </Grid>
          </a>
        </Grid>
        <Grid item xs={12} md={4} style={{ marginBottom: "15px" }}>
          <a
            className="footer__text"
            href="https://www.google.com/maps/place/Jr.+Piura+129,+Carmen+de+La+Legua+-+Reynoso+07006/@-12.043192,-77.097901,14z/data=!4m2!3m1!1s0x9105ceab48949897:0x869ee5e30cef983?hl=es-419&gl=PE"
            target="_blank"
            rel="noopener noreferrer"
          >
            <HiLocationMarker className="footer__icon" />
            <span className="footer__description footer__address">
              Jr. Piura N° 129 - Carmen de la Legua Reynoso, Callao - Lima, Perú
            </span>
          </a>
        </Grid>
        <Grid item xs={12}>
          <a href="https://www.facebook.com/coldfreshrv" target="_blank">
            <FaFacebook className="footer__redes" />
          </a>
          <a href="/">
            <FaInstagram className="footer__redes" />
          </a>
        </Grid>
        <Grid item xs={12}>
          <p className="footer__copy">Copyright &copy; Cold Fresh 2021</p>
        </Grid>
      </Grid>
    </div>
  );
};
export default Footer;
