import React from 'react';
import ConstructionAnimation from "../assets/json/68977-sitio-web-en-construccion.json";
import { Player } from "@lottiefiles/react-lottie-player";
import logo from "../assets/images/logo.png";
import logo2 from "../assets/images/logo2.jpg";

const Error = () => {
	return(
		<div className="App">
      <div className="container">
        <div className="div-img hidden">
          <img className="img" src={logo} title="logo" alt="coldfresh" />
          <div className="text">
            <img src={logo2} title="logo2" alt="logo2" />
          </div>
        </div>
      </div>
      <div className="player">
        <Player loop src={ConstructionAnimation} autoplay={true} />
      </div>
    </div>
	)
}

export default Error;