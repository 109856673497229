import React, { useRef, useEffect } from "react";
import carousel1 from "../assets/images/carousel.png";
import carousel2 from "../assets/images/carousel2.png";
import carousel3 from "../assets/images/carousel3.png";
import carousel4 from "../assets/images/carousel4.jpg";
import carousel5 from "../assets/images/carousel5.jpg";
import { makeStyles } from "@material-ui/core";
import Slider from "react-tiny-slider";

const useStyles = makeStyles({
  root: {
    minHeight: "100vh",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center center",
    backgroundSize: "cover",
    position: "relative",
  },
  content: {
    position: "absolute",
    left: "0",
    right: "0",
    bottom: "0",
    margin: "auto",
    backgroundColor: "rgba(0,0,0,0.7)",
  },
});

const Corousel = () => {
  const classes = useStyles();

  let items = [
    {
      name: "Instalación   y   mantenimiento   de   equipos   de   aire   acondicionado   y refrigeración",
      description:
        "Mantenimiento preventivo y correctivo residencial, comercial e industrial",
        image: carousel1,
    },
    {
      name: "Instalación de Ascensores",
      description:
        "Instalación de ascensores de pasajeros, montavehículos, montacargas y mantenimiento preventivo.",
      image: carousel3,
    },
    {
      name: "Instalación de Cámaras de Videovigilancia",
      description:
        "Cumpliendo con los estándares internacionales para un mayor tiempo de vida de los equipos.",
      image: carousel2,
    },
    {
      name: "Instalación de sistema contra incendio",
      description:
        "Dispositivos auditivos y visuales, luces intermitentes y sirenas.",
      image: carousel4,
    },
    {
      name: "Instalaciones eléctricas residenciales e industriales",
      description:
        "Servicio de instalación y reparaciones, montajes de tableros.",
      image: carousel5,
    },
  ];

  const carousel = useRef(null);

  useEffect(() => {
    setInterval(() => carousel.current.goTo("next"), 5000);
  }, []);

  return (
    <Slider
      items={1}
      ref={carousel}
      controls={false}
      nav={false}
      swipeAngle={false}
      mouseDrag
    >
      {items.map((e) => (
        <div
          style={{ backgroundImage: `url(${e.image})` }}
          className={classes.root}
        >
          <div className={classes.content}>
            <h2 style={{ color: "#ffcc00" }}>{e.name}</h2>
            <p style={{ color: "white" }}>{e.description}</p>
          </div>
        </div>
      ))}
    </Slider>
  );

  /* 
    {
        let items = [
            {
                name: "Servicios Generales",
                description: "Instalación y Mantenimiento de equipos de aire acondicionado y refrigeración.",
                image: carousel2
            },
            {
                name: "Instalación de Ascensores",
                description: "Instalación de ascensores de pasajeros, montavehículos, montacargas y mantenimiento preventivo.",
                image: mantAscen
            },
            {
                name: "Cámaras de Videovigilancia",
                description: "Instalación de sistema contra incendio: dispositivos auditivos y visuales, luces intermitentes y sirenas.",
                image: carousel1
            }
        ]

        return (
            <Carousel>
                {
                    items.map((item, i) => <Item key={i} item={item} />)
                }
            </Carousel>
        )
    }

    function Item(props) {
        return (
            <Paper className="paper">
                <img className="carousel__image" src={props.item.image} />
                <h2>{props.item.name}</h2>
                <p>{props.item.description}</p>
            </Paper>
        )
    } */
};

export default Corousel;
