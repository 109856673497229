import Routes from './routes';
import "./App.css";
import "./styles/main.scss";
import  NavBar  from "./components/NavBar";
import { BrowserRouter as Router } from "react-router-dom";
import React from 'react';
import Footer from './components/Footer';

function App() {
  return (
    <Router className="main">
      <NavBar/>
      <Routes/>
      <Footer/>
    </Router>

  );
}
  
export default App;
      
    
