import React from "react";
import { FaMedal, FaChartLine, FaHandshake, FaEye } from "react-icons/fa";

const list = [
  {
    icon: <FaMedal className="mision__icon" />,
    title: "Misión",
    description:
      "Mejorar la calidad de vida de nuestros clientes, con servicios integrales de infraestructura hechos a su medida.",
  },
  {
    icon: <FaEye className="mision__icon" />,
    title: "Visión",
    description: `Ser una empresa exitosa, reconocida y respetada por entregar un servicio de excelencia en la instalación y mantenimiento de Aire Acondicionado, Ascensores, Cámaras de video vigilancia y Sistema contra incendio.`,
  },
  {
    icon: <FaChartLine className="mision__icon" />,
    title: "Objetivo",
    description:
      "Constituirnos en los socios estratégicos en infraestructura, mantenimiento y servicios para instalaciones de nuestros clientes.",
  },
];

const MisionCard = () => {
  return (
    <div className="mision__container">
      {list.map((e) => (
        <div className="mision__card">
          {e.icon}
          <h3 className="mision__card__title">{e.title}</h3>
          <div className="mision__text">
            <p className="mision__card__description">{e.description}</p>
          </div>
        </div>
      ))}
      <div className="mision__card">
        <FaHandshake className="mision__icon" />
        <h3 className="mision__card__title">Valores</h3>
        <div className="mision__text" style={{ textAlign: "left" }}>
          <p
            className="mision__card__description"
            style={{ textAlign: "left" }}
          >
            <span className="mision__card__description__title">CALIDAD:</span>{" "}
            Satisfacer las expectativas de nuestros clientes.
          </p>
          <p
            className="mision__card__description"
            style={{ textAlign: "left" }}
          >
            <span className="mision__card__description__title">HONRADEZ:</span>{" "}
            Rectitud e Integridad en nuestros actos y servicios, que generen
            confianza en forma permanente.
          </p>
          <p
            className="mision__card__description"
            style={{ textAlign: "left" }}
          >
            <span className="mision__card__description__title">
              VOCACIÓN DE SERVICIO:
            </span>{" "}
            Convencimiento y pasión por servir a nuestros clientes.
          </p>
        </div>
      </div>
    </div>
  );
};
export default MisionCard;
