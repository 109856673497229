import React from 'react';
import AboutDescription from '../components/AboutDescription';
import MisionCard from '../components/MisionCard';
import Title from '../components/Title';

const About = () => {
	return(
		<div className="page">
			<Title title="¿Quienes somos?"/>
			<AboutDescription/>
			<MisionCard/>
		</div>
	)
}
export default About;