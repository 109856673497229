import React from 'react';
import ContactForm from '../components/ContactForm';
import Map from '../components/Map';
import Title from '../components/Title';

const Contact = () => {
	return(
		<div className="page">
			<Title title="Contáctanos"/>
			<ContactForm/>
			<Map/>
		</div>
	)
}
export default Contact;