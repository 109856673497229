import React from 'react';
import ServiceDescription from '../components/ServiceDescription';
import ServiceCards from '../components/ServiceCards'
import Title from '../components/Title';

const About = () => {
	return(
		<div className="page">
			<Title title="Servicios"/>
			<ServiceDescription/>
			<ServiceCards/>
		</div>
	)
}
export default About;