import React from "react";
import { Route, Switch, withRouter } from "react-router-dom";
import Home from "./pages/Home";
import About from "./pages/About";
import Services from "./pages/Services";
import Contact from "./pages/Contact";
import Page404 from "./pages/Page404";

const Routes = () => {
  window.scrollTo(0, 0);
  return (
    <Switch>
      <Route path="/" exact={true} component={Home} />
      <Route path="/about" exact={true} component={About} />
      <Route path="/services" exact={true} component={Services} />
      <Route path="/contact" exact={true} component={Contact} />
      <Route path="*" component={Page404} />
    </Switch>
  );
};
export default withRouter(Routes);
