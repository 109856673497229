import React from 'react';
import tecnico from '../assets/images/tecnico.jpg';

const about = [
	{
		title:'NUESTRA EMPRESA',
		description: 'Somos una empresa peruana especializada en brindar mantenimiento e instalación de ascensores, aires acondicionados, cámaras de seguridad y sistemas contra incendio. Todo con servicios técnicos de calidad A1, gracias a nuestros técnicos especializados.'
	}
]

const AboutDescription = () => {
	return(
		<div className="about__container">
			<img className="about__image" src={tecnico} alt="tecnico" />
			<div className="about__description">
				{about.map((e)=>(
					<div>
						<h3>{e.title}</h3>
						<p>{e.description}</p>
					</div>
				))}
			</div>
		</div>
	)
}
export default AboutDescription;