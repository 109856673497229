import React from "react";
import camaras from "../assets/images/camaras.jpg";
import ascensor2 from "../assets/images/ascensor2.jpg";
import instalAire2 from "../assets/images/instalAire2.jpg";
import alarma from "../assets/images/alarma.jpg";
import electricidadResidencial from "../assets/images/electricidad-residencial.jpg";

const list = [
  {
    title:
      "Instalación y Mantenimiento de equipos de aire acondicionado y refrigeración",
    description:
      "Mantenimiento preventivo y correctivo residencial, comercial e industrial.",
    image: instalAire2,
  },
  {
    title:
      "Instalación, montaje y servicio de mantenimiento preventivo de  Ascensores",
    description:
      "Instalación  de  ascensores de pasajeros, montavehículos, montacargas y mantenimiento preventivo.",
    image: ascensor2,
  },
  {
    title: "Instalación y mantenimiento de Cámaras de Seguridad",
    description:
      "Instalación de sistemas de videovigilancia ip y analógicas, CCTV mediante NVR y DVR cumpliendo con los estándares internacionales para un mayor tiempo de vida de los equipos.",
    image: camaras,
  },
  {
    title: "Instalación y mantenimiento de sistemas contra Incendios",
    description:
      "Instalación de sistema contra incendio: dispositivos auditivos y visuales, luces intermitentes y sirenas.",
    image: alarma,
  },
  {
    title: "Instalaciones eléctricas residenciales e industriales",
    description:
      "Servicio de instalación y reparaciones, montajes de tableros e instalación y mantenimiento de pozo a tierra.",
    image: electricidadResidencial,
  },
];

const ServiceCards = () => {
  return (
    <div className="service-card-container">
      {list.map((e) => (
        <div className="service-card flip-card">
          <div
            className="flip-card-inner"
            style={{
              width: "100%",
              height: "100%",
            }}
          >
            <div
              className="flip-card-front"
              style={{
                width: "100%",
                height: "100%",
              }}
            >
              <div
                className="service-card-image"
                style={{ backgroundImage: `url(${e.image})` }}
              ></div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "100%",
                  backgroundColor: "rgba(0, 0, 0, 0.6)",
                }}
              >
                <h3 className="service-card-title">{e.title}</h3>
              </div>
            </div>
            <div className="flip-card-back">
              <p style={{ fontWeight: "600" }}>{e.description}</p>
              <a href="#" className="flip-card-back-link">
                <div>Ver más</div>
              </a>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};
export default ServiceCards;