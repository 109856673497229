import Error from '../components/Error';
import React from 'react';

const Page404 = () => {
	return(
		<div className="page">
			<Error/>
		</div>
	)
}
export default Page404