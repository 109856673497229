import React, { useState, Fragment } from "react";
import logo from '../assets/images/logo.png';
import logo2 from '../assets/images/logo2-alternativo.png';
import { Link } from 'react-router-dom';
import { VscMenu, VscClose } from "react-icons/vsc";

const list = [
	{
		name: "INICIO",
		link: "/"
	},
	{
		name: "NOSOTROS",
		link: "/about"
	},
	{
		name: "SERVICIOS",
		link: "/services"
	},
	{
		name: "CONTACTO",
		link: "/contact"
	},
];


const NavBar = () => {
	const [openNav, setOpenNav] = useState(false);
	const [colorChange, setColorchange] = useState(false);
	const changeNavbarColor = () => {
		if (window.scrollY >= 80) {
			setColorchange(true);
		}
		else {
			setColorchange(false);
		}
	};
	window.addEventListener('scroll', changeNavbarColor);
	return (
		<Fragment >
		<nav >
			<div className={`nav ${colorChange ? 'navbar colorChange' : 'nav__scroll'}` }>
				<div className="nav__logo__container">
					<Link to={{ pathname: "/" }} className="test3">
						<img className="nav__logo nav__casco" src={logo} alt="logo1" />
						<img className="nav__logo nav__nombre" src={logo2} alt="logo2" />
					</Link>
				</div>
				<div className="nav__menuToggle">
					{openNav ? (
						<VscClose onClick={() => setOpenNav(false)} />
					) : (
						<VscMenu onClick={() => setOpenNav(true)} />
					)}
				</div>
				<ul className={`nav__button__container ${openNav ? "active" : "navscroll"
					}`}>
					{list.map((e) => (
						<li className="nav__button ">
							<Link to={e.link} onClick={() => setOpenNav(false)}>
								{e.name}
							</Link>
						</li>
					))}
				</ul>
			</div>
		</nav >
		</Fragment>
	)
}
export default NavBar;
