import { Button, Container, Grid, TextField } from '@material-ui/core';
import React from 'react';
import { createTheme, ThemeProvider } from '@material-ui/core';
const theme = createTheme({
  palette: {
    primary: {
      main: "#000",
    },
  }
});

const ContactForm = () => {
	return(
		<ThemeProvider theme={theme}>

		<form className="form">
        <Container maxWidth="lg">
          <Grid
            container
            direction="row"
            justify="center"
            alignItems="center"
            spacing={3}
          >
            <Grid item xs={10} md={5}>
              <TextField
                id="filled-secondary"
                label="Nombre"
                required
                variant="filled"
                fullWidth
								className="form__text"
              />
            </Grid>
            <Grid item xs={10} md={5}>
              <TextField
                id="filled-secondary"
                label="Email"
                required
                variant="filled"
                fullWidth
								className="form__text"
              />
            </Grid>
            <Grid item xs={10} md={5}>
              <TextField
                id="filled-secondary"
                label="Asunto"
                required
                variant="filled"
                fullWidth
								className="form__text"
              />
            </Grid>
            <Grid item xs={10} md={5}>
              <TextField
                id="filled-secondary"
                label="Teléfono"
                required
                variant="filled"
                color="primary"
                fullWidth
              />
            </Grid>
            <Grid item xs={10} md={10}>
              <TextField
                id="filled-secondary"
                label="Mensaje"
                required
                variant="filled"
                color="primary"
                fullWidth
                multiline
                rows={6}
              />
            </Grid>
            <Grid
              container
              direction="row"
              justify="center"
              alignItems="center"
            >
              <Grid item align="center">
                <Button className="form__button" variant="contained" type="submit" size="large">
                  ENVIAR
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Container>
      </form>
		</ThemeProvider>
	)
}

export default ContactForm;